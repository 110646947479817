import { SVG } from '@svgdotjs/svg.js'

import leaf1 from 'bundle-text:../img/leaf1.symbol';
import leaf2 from 'bundle-text:../img/leaf2.symbol';
import leaf3 from 'bundle-text:../img/leaf3.symbol';

Array.prototype.sample = function(){
    return this[Math.floor(Math.random()*this.length)];
}

const background = SVG('#main-title').findOne('g');

SVG(leaf1).addTo(background);
SVG(leaf2).addTo(background);
SVG(leaf3).addTo(background);

const leaves = Array.from({length: 3}, (_, index) => `leaf${index+1}`);

//const width = 740;
const height = 441;

const gridWidth = 20;
const gridHeight = 11;

setTimeout(() => {
    for (let i = 0; i < 60; i++) {    
        const gridX = (gridWidth - 1) - Math.floor(i % gridWidth);
        const gridY = (gridHeight - 1) - Math.floor(i / gridHeight);
    
        const group = background
            .group()
            .translate(gridX * 64, -128)
    
        const leafRunner =
            group
            .use(leaves.sample())
            .size(128, 128)
            .rotate(-8)
            .animate({ duration: 2000, swing: true, times: Infinity, delay: Math.floor(Math.random() * 400) })
            .rotate(16)        
    
        group
            .animate({ duration: 5000, delay: Math.floor(Math.random() * 5000) })
            .translate(0, 128 + (height / gridHeight) * (gridY))
            .after(() => {
                leafRunner.unschedule();
            });
        
    }
}, 1000);

document.addEventListener('contextmenu', event => event.preventDefault());

const addClass = (image) => { if (!image.classList.contains('fade-in')) image.classList.add('fade-in'); }

const observer = new IntersectionObserver((entries) => {
    for (const entry of entries) {
        if (entry.intersectionRatio > 0) {
            const image = entry.target;

            if (image.complete && image.naturalHeight !== 0) {
                addClass(image);
            } else {
                image.addEventListener('load', (load) => {
                    addClass(image);
                });
            }
        }
    }
}, { threshold: 0.5 });

for (const image of document.querySelectorAll('main img')) {
    observer.observe(image);
}